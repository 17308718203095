<template>
  <div class="w-full py-6">
    <div class="flex items-center justify-center">
      <div class="w-1/4" :key="index" v-for="(item, index) in 3">
        <div class="relative mb-2">
          <template v-if="index > 0">
            <div
              v-if="index <= currentStep"
              class="
                absolute
                flex
                align-center
                items-center
                align-middle
                content-center
              "
              style="
                width: calc(100% - 2.5rem - 1rem);
                top: 50%;
                transform: translate(-50%, -50%);
              "
            >
              <div
                class="
                  w-full
                  bg-gray-200
                  rounded
                  items-center
                  align-middle align-center
                  flex-1
                "
              >
                <div
                  class="w-0 bg-brand py-1 rounded"
                  style="width: 100%"
                ></div>
              </div>
            </div>

            <div
              v-else
              class="
                absolute
                flex
                align-center
                items-center
                align-middle
                content-center
              "
              style="
                width: calc(100% - 2.5rem - 1rem);
                top: 50%;
                transform: translate(-50%, -50%);
              "
            >
              <div
                class="
                  w-full
                  bg-gray-200
                  rounded
                  items-center
                  align-middle align-center
                  flex-1
                "
              >
                <div
                  class="w-0 bg-green-300 py-1 rounded"
                  style="width: 0%"
                ></div>
              </div>
            </div>
          </template>
          <div
            :class="index <= currentStep ? activeClasses : inActiveClasses"
            class="
              w-10
              h-10
              mx-auto
              rounded-full
              text-lg text-white
              flex
              items-center
            "
          >
            <span
              :class="index <= currentStep ? 'text-white' : 'text-brand'"
              class="text-center text-black w-full"
            >
              <p class="font-bold text-xl">{{ item }}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LSteps",
  props: {
    currentStep: {
      default: 0,
      type: [Number, String],
    },
    activeClasses: {
      default: "",
      type: [String],
    },
    inActiveClasses: {
      default: "",
      type: [String],
    },
  },
};
</script>

<style scoped></style>
