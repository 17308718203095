<template>
  <div
    class="
      w-full
      bg-white
      px-6
      py-6
      space-y-5
      rounded-lg
      shadow
      container
      mx-auto
    "
  >
    <div class="flex items-center justify-center w-full">
      <l-steps
        :current-step="currentStep"
        in-active-classes="bg-gray-300"
        active-classes="bg-brand"
      >
      </l-steps>
    </div>
    <div v-if="pinLengthError" class="">
      <div class="flex w-full justify-center items-center py-4">
        <p>
          Product pin length {{ this.pinLength }} didn't match with file pin
          length {{ this.stepTwoResponse.pin_length }}
        </p>
      </div>
      <div class="flex w-full justify-center items-center space-x-4">
        <router-link to="/inventory">
          <button type="button" class="danger-button">
            {{ $t("cancel") }}
          </button>
        </router-link>
      </div>
    </div>
    <template v-else>
      <div class="" v-if="currentStep == 0">
        <div class="grid grid-cols-2">
          <div class="flex items-start w-full">
            <label class="col-span-2 w-full flex flex-col items-start">
              <span class="text-gray-700">{{ $t("delimiter") }}</span>
              <select
                name="delimiter"
                v-model="delimiter"
                class="
                  block
                  w-full
                  mt-1
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
              >
                <option
                  :key="index"
                  v-for="(delimiter, index) in delimiterData"
                  :value="delimiter.value"
                >
                  {{ delimiter.name }} ({{ delimiter.value }})
                </option>
              </select>
            </label>
          </div>

          <div
            class="flex items-center justify-center flex-col w-full space-y-4"
          >
            <label
              class="
                px-8
                space-x-4
                flex flex-row
                items-center
                px-4
                py-6
                bg-white
                text-blue
                rounded-lg
                shadow-lg
                tracking-wide
                uppercase
                border border-blue
                cursor-pointer
                hover:bg-brand hover:text-white
              "
            >
              <CloudUploadIcon class="h-12 w-12" />
              <span class="mt-2 text-base leading-normal text"
                >Select a file</span
              >
              <input
                type="file"
                class="hidden"
                accept=".csv"
                ref="fileUpload"
                @change="selectedNewFile"
              />
            </label>
            <TransitionRoot
              appear
              :show="inventoryCsv != null"
              as="template"
              enter="transition ease-in-out duration-300 transform"
              enter-from="-translate-x-full"
              enter-to="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leave-from="translate-x-0"
              leave-to="-translate-x-full"
            >
              <div class="flex flex-row space-x-3">
                <p>{{ inventoryCsv == null ? "" : inventoryCsv.name }}</p>
                <l-icon-button
                  @click="deleteSelectedFile"
                  classes="bg-red-500 w-8 h-8 flex items-center justify-center rounded-full cursor-pointer"
                >
                  <template v-slot:icon>
                    <TrashIcon class="h-5 w-5 text-white" aria-hidden="true" />
                  </template>
                </l-icon-button>
              </div>
            </TransitionRoot>
          </div>
        </div>
        <div class="flex w-full justify-center items-center space-x-4">
          <router-link to="/inventory">
            <button type="button" class="danger-button">
              {{ $t("cancel") }}
            </button>
          </router-link>
          <button @click="checkStepOne" class="brand-button">
            {{ $t("next") }}
          </button>
        </div>
      </div>

      <div
        class="w-full flex items-center justify-center"
        v-if="currentStep == 1"
      >
        <Form
          @submit="checkStepTwo"
          v-slot="{ meta }"
          :validation-schema="schema"
          class="flex flex-col w-1/2 text-left space-y-3"
        >
          <label class="col-span-2 w-full flex flex-col items-start">
            <span class="text-gray-700">{{ $t("product") }}</span>
            <Field
              as="select"
              name="product"
              v-model="selectedProduct"
              @change="onProductSelected"
              class="
                block
                w-full
                mt-1
                rounded-md
                bg-gray-100
                border-transparent
                focus:border-gray-500 focus:bg-white focus:ring-0
              "
            >
              <option value="">{{ $t("select") }}</option>
              <option
                :key="index"
                v-for="(product, index) in products"
                :value="product.product_id"
              >
                {{ product.product_name }}
              </option>
            </Field>
          </label>
          <label class="col-span-2 w-full flex flex-col items-start">
            <span class="text-gray-700">{{ $t("denomination") }}</span>
            <Field
              as="select"
              name="denomination"
              v-model="selectedDenomination"
              class="
                block
                w-full
                mt-1
                rounded-md
                bg-gray-100
                border-transparent
                focus:border-gray-500 focus:bg-white focus:ring-0
              "
            >
              <option value="">{{ $t("select") }}</option>
              <option
                :key="index"
                v-for="(denomination, index) in denominationsList"
                :value="denomination.denomination_id"
              >
                {{ $filters.euroFormat(denomination.value) }}
              </option>
            </Field>
          </label>
          <label class="col-span-2 w-full flex flex-col items-start">
            <span class="text-gray-700">{{ $t("batchSequence") }}</span>
            <Field
              as="select"
              name="batchSequence"
              v-model="selectedBatchSequence"
              class="
                block
                w-full
                mt-1
                rounded-md
                bg-gray-100
                border-transparent
                focus:border-gray-500 focus:bg-white focus:ring-0
              "
            >
              <option value="">{{ $t("select") }}</option>
              <option
                :key="index"
                v-for="(option, index) in csvOptions"
                :value="option.sequence"
              >
                {{ option.parameter }}
              </option>
            </Field>
          </label>
          <label class="col-span-2 w-full flex flex-col items-start">
            <span class="text-gray-700">{{ $t("pinSequence") }}</span>
            <Field
              as="select"
              name="pinSequence"
              v-model="selectedPinSequence"
              class="
                block
                w-full
                mt-1
                rounded-md
                bg-gray-100
                border-transparent
                focus:border-gray-500 focus:bg-white focus:ring-0
              "
            >
              <option value="">{{ $t("select") }}</option>
              <option
                :key="index"
                v-for="(option, index) in csvOptions"
                :value="option.sequence"
              >
                {{ option.parameter }}
              </option>
            </Field>
          </label>
          <l-input-field
            :enabled="false"
            v-if="showPinLength"
            :value="pinLength"
            name="pinLength"
            type="number"
            :label="$t('pinLength')"
          >
          </l-input-field>

          <div class="flex w-full justify-center items-center space-x-4">
            <router-link to="/inventory">
              <button type="button" class="danger-button">
                {{ $t("cancel") }}
              </button>
            </router-link>
            <button
              type="submit"
              class="brand-button"
              :class="{
                'opacity-50 cursor-not-allowed': !meta.valid,
                'opacity-100 cursor-pointer': meta.valid,
              }"
            >
              {{ $t("next") }}
            </button>
          </div>
        </Form>
      </div>

      <div
        class="w-full flex flex-col items-center justify-center"
        v-if="currentStep == 2"
      >
        <div class="flex flex-row space-x-4 pb-4">
          <div
            class="
              bg-green-400
              shadow-lg
              px-4
              py-2
              rounded-lg
              text-center
              items-center
              flex flex-col
            "
          >
            <CheckIcon class="h-6 w-6" />
            <p>
              {{ $t("valid") }}
              <strong>{{ this.stepTwoResponse.sucessful_pins }}</strong>
              {{ $tc("pin", 2) }}
            </p>
          </div>
          <div
            class="
              bg-red-400
              shadow-lg
              px-4
              py-2
              rounded-lg
              text-center
              items-center
              flex flex-col
            "
          >
            <XIcon class="h-6 w-6" />
            <p>
              {{ $t("invalid") }}
              <strong>{{ this.stepTwoResponse.unsucessful_pins }}</strong>
              {{ $tc("pin", 2) }}
            </p>
          </div>
        </div>
        <Form
          @submit="checkStepThree"
          :validation-schema="threeSchema"
          class="flex flex-col w-1/2 text-left space-y-3"
        >
          <label class="col-span-2 w-full flex flex-col items-start">
            <span class="text-gray-700">{{ $t("supplier") }}</span>
            <Field
              as="select"
              name="supplier"
              v-model="selectedSupplier"
              class="
                block
                w-full
                mt-1
                rounded-md
                bg-gray-100
                border-transparent
                focus:border-gray-500 focus:bg-white focus:ring-0
              "
            >
              <option value="">{{ $t("select") }}</option>
              <option
                :key="index"
                v-for="(supplier, index) in suppliers"
                :value="supplier.supplier_id"
              >
                {{ supplier.full_name }}
              </option>
            </Field>
          </label>
          <l-input-field
            :value="buyingDiscount"
            name="buyingDiscount"
            type="number"
            :label="$t('buyingDiscount')"
          >
          </l-input-field>

          <div class="flex w-full justify-center items-center space-x-4">
            <router-link to="/inventory">
              <button type="button" class="danger-button">
                {{ $t("cancel") }}
              </button>
            </router-link>
            <button type="submit" class="brand-button">
              {{ $t("submit") }}
            </button>
          </div>
        </Form>
      </div>
    </template>

    <div v-if="formCompleted" class="">
      <div class="flex w-full justify-center items-center py-4">
        <BadgeCheckIcon class="w-32 h-32 text-green-400 animate-scale" />
      </div>
      <div class="flex w-full justify-center items-center space-x-4">
        <router-link to="/inventory">
          <button type="button" class="brand-button">
            {{ $t("done") }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { TransitionRoot } from "@headlessui/vue";
import LSteps from "@/components/custom/steps/LSteps";
import { CloudUploadIcon, BadgeCheckIcon } from "@heroicons/vue/solid";
import { TrashIcon, CheckIcon, XIcon } from "@heroicons/vue/outline";
import LIconButton from "@/components/custom/LIconButton";
import { default as toast } from "@/utils/toastUtils";
import LInputField from "@/components/custom/LInputField";
import { mapGetters } from "vuex";
import { useForm, Field, Form } from "vee-validate";
import * as yup from "yup";
import { ref } from "vue";

export default {
  name: "AddInventory",
  components: {
    LInputField,
    LIconButton,
    LSteps,
    CloudUploadIcon,
    TransitionRoot,
    TrashIcon,
    CheckIcon,
    XIcon,
    BadgeCheckIcon,
    Field,
    Form,
  },
  setup() {
    // Define a validation schema
    const schema = yup.object({
      product: yup.string().required(),
      denomination: yup.string().required(),
      batchSequence: yup.string().required(),
      pinSequence: yup.string().required(),
      pinLength: yup.number().required(),
    });

    const { meta, errors } = useForm({
      validationSchema: schema,
    });

    const threeSchema = yup.object({
      buyingDiscount: yup.number().required(),
      supplier: yup.string().required(),
    });

    const { threeMeta } = useForm({
      validationSchema: threeSchema,
    });

    const showPinLength = ref(false);

    return {
      schema,
      meta,
      threeMeta,
      threeSchema,
      showPinLength,
    };
  },
  data() {
    return {
      currentStep: 0,
      delimiter: ",",
      inventoryCsv: null,
      pinLength: "",
      importBatchNo: "",
      stepOneResponse: null,
      stepTwoResponse: null,
      csvOptions: [],
      delimiterData: [
        {
          name: "Comma",
          value: ",",
        },
        {
          name: "Tab Space",
          value: "\\t",
        },
        {
          name: "Colon",
          value: ":",
        },
        {
          name: "Semi Colon",
          value: ";",
        },
      ],
      selectedProduct: "",
      denominationsList: [],
      selectedDenomination: "",
      selectedBatchSequence: "",
      selectedPinSequence: "",
      pinLengthError: false,
      selectedSupplier: "",
      buyingDiscount: "",
      formCompleted: false,
    };
  },
  computed: {
    ...mapGetters(["products", "suppliers"]),
  },
  methods: {
    async selectedNewFile() {
      this.inventoryCsv = this.$refs.fileUpload.files[0];
    },
    deleteSelectedFile() {
      this.inventoryCsv = null;
      this.$refs.fileUpload.value = "";
    },
    fetchProducts() {
      this.$store.dispatch("fetchProductsList", {
        limit: 100,
      });
    },
    fetchSuppliers() {
      this.$store.dispatch("fetchSuppliersList", {
        limit: 100,
      });
    },
    checkStepOne() {
      if (this.inventoryCsv == null) {
        toast.error("Please select CSV file");
        return;
      }
      let data = {
        csv_file: this.inventoryCsv,
        delimiter: this.delimiter,
      };
      this.$store
        .dispatch("inventoryStepOne", data)
        .then((data) => {
          this.stepOneResponse = data;
          this.importBatchNo = this.stepOneResponse.import_batch;
          this.csvOptions = this.stepOneResponse.parameters;
          this.currentStep = 1;
        })
        .catch((err) => {
          toast.error(err);
        });
    },
    checkStepTwo(values) {
      console.log(values);
      let data = {
        denomination_id: values.denomination,
        pin_sequence: values.pinSequence,
        batch_sequence: values.batchSequence,
        import_batch: this.importBatchNo,
      };
      this.$store
        .dispatch("inventoryStepTwo", data)
        .then((data) => {
          this.stepTwoResponse = data;
          if (this.stepTwoResponse.pin_length != this.pinLength) {
            this.pinLengthError = true;
            toast.error(
              `Product pin length ${this.pinLength} didn't match with file pin length ${this.stepTwoResponse.pin_length}}`
            );
            return;
          }
          this.currentStep = 2;
        })
        .catch((err) => {
          toast.error(err);
        });
    },
    checkStepThree(values) {
      if (this.stepTwoResponse.sucessful_pins == 0) {
        this.currentStep = 3;
        this.formCompleted = true;
      } else {
        let data = {
          supplier_id: values.supplier,
          buying_discount: values.buyingDiscount,
          import_batch: this.importBatchNo,
        };
        this.$store
          .dispatch("inventoryStepThree", data)
          .then((data) => {
            toast.success(data.message);
            this.currentStep = 3;
            this.formCompleted = true;
          })
          .catch((err) => {
            toast.error(err);
          });
      }
    },
    onProductSelected(product) {
      this.showPinLength = false;
      this.$store
        .dispatch("fetchProduct", { product_id: this.selectedProduct })
        .then((response) => {
          let data = response.product;
          this.pinLength = data.pin_length;
          console.log(this.pinLength);
          this.showPinLength = true;
          this.denominationsList = data.denominations;

          this.showForm = true;
        })
        .catch((err) => {
          toast.error(err);
        });
    },
  },
  mounted() {
    this.fetchProducts();
    this.fetchSuppliers();
  },
};
</script>

<style scoped></style>
